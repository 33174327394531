// @ts-ignore
import { addBackToTop } from 'vanilla-back-to-top';
import { primaryInput } from 'detect-it';
import './styles.scss';

addBackToTop({
  id: 'anw-back-to-top',
  diameter: 50,
  innerHTML: '<i class="fas fa-chevron-up" aria-hidden="true"></i>',
  showWhenScrollTopIs: window.innerHeight,
});

const breakpoint: number = 1024;
let scrollPos: number = 0;
let backToTopButton: HTMLElement | null = document.getElementById('anw-back-to-top');

window.addEventListener('scroll', function () {
  if (primaryInput === 'touch' || window.innerWidth < breakpoint) {
    toggleBackToTopButton();
  }
});

window.addEventListener('resize', function () {
  if (window.innerWidth < breakpoint) {
    toggleBackToTopButton();
  }
});

if (primaryInput === 'touch') {
  toggleBackToTopButton();
}

function toggleBackToTopButton() {
  //scrolling up
  if (document.body.getBoundingClientRect().top > scrollPos) {
    if (backToTopButton && backToTopButton.classList.contains('d-none')) {
      backToTopButton.classList.remove('d-none');
    }
  }
  //scrolling down
  if (document.body.getBoundingClientRect().top < scrollPos) {
    if (backToTopButton && !backToTopButton.classList.contains('d-none')) {
      backToTopButton.classList.add('d-none');
    }
  }
  scrollPos = document.body.getBoundingClientRect().top;
}
